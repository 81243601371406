'use strict';

import { setUserInfo, clearUserInfo } from '../modules/shared/utils/userInfoUtils';

angular.module('app').factory('AuthService', AuthService);

AuthService.$inject = ['$q', 'ApiService', '$location', 'Base64', 'utilService', 'ModalService',
    'constants','$window'];

function AuthService($q, ApiService, $location, Base64, utilService, ModalService, constants, $window) {

    const authService = {
        signIn: function (email, password, mfaLogin, params = null) {
            return ApiService.post(mfaLogin ? 'api/mfa/cookie-login' : 'api/cookie-login', null, params,
                {'Authorization': 'Basic ' + Base64.encode(email + ':' + password)}).then(function (res) {
                var user = res.data;

                if (res.status === 210) {
                    authService.setUserInfo({ corporateAccountInfo: user });
                    utilService.goTo('/pin-code');
                } else if (res.status !== 211) {
                    authService.setUserInfo(user).then(() => {
                        utilService.nextScreen();
                    });
                }

                return res;
            }, function (err) {
                    if (err.status === 460) {
                        authService.existSessionHandler(email, password, err);
                    }

                    return err;
                }
            );
        },
        corporateSignIn: function (pinCode, corporateAccountInfo) {
            return ApiService.post('api/corporate/cookie-login', {pincode: pinCode}, false, {'corporate-code': corporateAccountInfo.token}).then(function (res) {
                let userInfo = res.data;
                userInfo.corporateAccountInfo = corporateAccountInfo

                authService.setUserInfo(userInfo);

                utilService.goTo('/customers-dashboard');

                return res;
            }, function (err) {
                return err;
            });
        },
        auth0SingIn: function () {
            return ApiService.get('api/oauth/login/auth0').then(function (res) {
                $window.location.href = res.data.redirectUrl;
                return res;
            }, function (err) {
                return err;
            });
        },
        continueAuth0SingIn: function () {
            return ApiService.get('api/user/profile').then(function (res) {
                authService.setUserInfo(res.data).then(() => {
                    $location.search({}); // clear login-by-auth0=true
                    utilService.nextScreen();
                });

                return res;
            }, function (err) {
                return err;
            });
        },
        resetPassword: function (prms) {
            return ApiService.post('api/public/resetpasswordlink', null, prms).then(function (res) {
                ModalService.alert('Message with reset link sent to email.', 'Success', utilService.goTo('/'));
                return res;
            }, function (err) {
                return err
            });
        },
        signOut: function () {
            return ApiService.get('api/logout').then(function (res) {
                authService.clearUserInfo();
                utilService.goTo('/');
                $window.location.reload();

                return res;
            }, function (err) {
                return err;
            });
        },
        processShowPage(isShowPage) {
            const defer = $q.defer();

            if (isShowPage) {
                defer.resolve();
            } else {
                utilService.nextScreen();
            }

            return defer.promise;
        },
        skipIfLoggedIn() {
            const isUserLoggedIn = !utilService.getLoggedInUserData();

            return this.processShowPage(isUserLoggedIn);
        },
        termsRequired() {
            const user = utilService.getLoggedInUserData();
            const isShowTermsAndCondition = this.getIsShowTermsAndCondition(user);

            return this.processShowPage(isShowTermsAndCondition);
        },
        pinCodeRequired() {
            const isCorporateSignInUnfinished = utilService.getIsCorporateSignInUnfinished();

            return this.processShowPage(isCorporateSignInUnfinished);
        },
        loginRequired: function () {
            const defer = $q.defer();
            const user = utilService.getLoggedInUserData();
            const isCorporateSignInUnfinished = utilService.getIsCorporateSignInUnfinished();
            const isShowTermsAndCondition = this.getIsShowTermsAndCondition(user);

            if (!user) {
                utilService.goTo('/');
            } else if (isCorporateSignInUnfinished) {
                utilService.goTo('/pin-code');
            } else if (isShowTermsAndCondition) {
                utilService.goTo('/terms-and-conditions');
            } else {
                defer.resolve();
            }

            return defer.promise;
        },
        getIsShowTermsAndCondition(user) {
            if (!user) {
                return false;
            };

            const isCoach = utilService.getIsCoachRole();
            const isEntity = utilService.getIsAdminRole();
            const isNeedTermsAndCondition = isCoach || isEntity;
            const isNewTermsAndCondition = user.readTermsAndConditionVersion !== constants.termsAndConditionVersion;

            return isNewTermsAndCondition && isNeedTermsAndCondition;
        },
        parentToChildLogin: function (adminId) {
            const params = {adminId};
            return ApiService.post('api/admin/child-admin-login', null, params).then(res => {
                this.handleParentChildLoginResponse(res);
            }, function (err) {
                return err
            });
        },
        childToParentLogin: function () {
            return ApiService.post('api/admin/back-to-parent').then(res => {
                this.handleParentChildLoginResponse(res);
            }, function (err) {
                return err
            });
        },
        handleParentChildLoginResponse: function (response) {
            if (response.status === 200) {
                const userData = response.data;
                authService.setUserInfo(userData).then(() => {
                    utilService.nextScreen();
                });
            }

            return response;
        },
        existSessionHandler: (email, password, error) => {
            const { title, message } = error.data;

            const onSuccess = () => {
                const params = { deleteOtherSessions: true };
                authService.signIn(email, password, false, params);
            };

            ModalService.confirm(title, onSuccess, null, message, 'OK', 'Force Login');
        },
        setUserInfo: setUserInfo,
        clearUserInfo: clearUserInfo,
    };

    return authService;
}

