import { useEffect, useState } from 'react';

import { ConsumptionInfo } from '../../../interfaces/consumption/ConsumptionInfo';
import { ConsumptionParams } from '../../../interfaces/consumption/ConsumptionParams';
import { complianceService } from '../../../services/complianceService';
import { defaultConsumptionInfo } from '../../../constants/defaultConsumptionInfo';
import { getFormattedDate } from '../../../../shared/utils/dateUtils';
import { dateFormat, localDataFormat } from '../../../../shared/constants/apiDateFormat';

const scanDateFormat = 'MM.DD.YYYY';

export const useConsumptionInfo = (customerId: number, scanDates: string[]) => {
  const [consumptionInfo, setConsumptionInfo] =
    useState<ConsumptionInfo>(defaultConsumptionInfo);

  const lastScanDay = scanDates[0];
  const previousScanDay = scanDates[1];
  const isLessTwoScansExist = scanDates.length < 2;

  const formattedFrom = isLessTwoScansExist
    ? localDataFormat
    : getFormattedDate(previousScanDay, localDataFormat, scanDateFormat);

  const formattedTo = isLessTwoScansExist
    ? localDataFormat
    : getFormattedDate(lastScanDay, localDataFormat, scanDateFormat);

  const getConsumptionInfo = async () => {
    const consumptionParams: ConsumptionParams = {
      startDate: getFormattedDate(previousScanDay, dateFormat, scanDateFormat),
      endDate: getFormattedDate(lastScanDay, dateFormat, scanDateFormat),
    };

    const info = await complianceService.getCustomerConsumptionInfo(
      customerId,
      'SCAN',
      consumptionParams,
    );

    setConsumptionInfo(info);
  };

  useEffect(() => {
    if (!isLessTwoScansExist) {
      getConsumptionInfo();
    }
  }, [customerId, isLessTwoScansExist]);

  return { consumptionInfo, formattedFrom, formattedTo, isLessTwoScansExist };
};
