import React from 'react';

import { angularize } from 'react-in-angularjs';

import { SystemProviders } from '../../../../../shared/providers';
import { LbBigModal } from '../../../../../shared/components/modals';
import { CompareScanTable } from './CompareScanTable/CompareScanTable';
import { InbodyScanMetrics } from '../../../../interfaces/customerInbodyData';
import { InbodyType } from '../../../../../shared/constants/inbody-info/InbodyType';
import { useCompareScans } from './hooks/useCompareScans';
import { CompareDateForm } from './CompareDateForm/CompareDateForm';

type NgzCompareScanMetricsModalProps = {
  scansInfo: InbodyScanMetrics[];
  scanDateTimes: string[]; // 2023-04-13T11:55:06
  inbodyType: Exclude<InbodyType, 'NON_INBODY'>;
  onClose: () => void;
};

const NgzCompareScanMetricsModal = ({
  scanDateTimes,
  scansInfo,
  inbodyType,
  onClose,
}: NgzCompareScanMetricsModalProps) => {
  const { startScan, endScan, getCompareScans } = useCompareScans(scansInfo);

  return (
    <SystemProviders>
      <LbBigModal
        open
        title="Compare Scan Metrics"
        buttonOptions={{ yesVisible: false, noText: 'Close', noHandler: onClose }}
      >
        <CompareDateForm onSubmit={getCompareScans} scanDateTimes={scanDateTimes} />
        <CompareScanTable
          startScan={startScan}
          endScan={endScan}
          inbodyType={inbodyType}
        />
      </LbBigModal>
    </SystemProviders>
  );
};

angularize(
  NgzCompareScanMetricsModal,
  'ngzCompareScanMetricsModal',
  angular.module('app.inbody'),
  {
    scansInfo: '<',
    scanDateTimes: '<',
    inbodyType: '<',
    onClose: '&',
  },
);

export { NgzCompareScanMetricsModal };
