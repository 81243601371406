import { chatStore } from '../../modules/chat/stores/chatStore';
import { getCustomerAvailableModules } from '../../modules/shared/utils/paidModulesUtils';
import { chatService } from '../../modules/chat/services/chatService';
import { CUSTOMER_PROFILE_TAB_NAMES } from '../../modules/shared/constants/tabNames/CUSTOMER_PROFILE_TAB_NAMES';
import { pathQueryParams } from '../../modules/shared/constants/pathQueryParams';

(function () {
    'use strict';

    angular.module('app.customerProfile')
        .controller('CustomerProfileController', CustomerProfileController);

    CustomerProfileController.$inject = [
        '$scope',
        '$rootScope',
        '$location',
        '$routeParams',
        '$mdDialog',
        'utilService',
        'ModalService',
        'CustomerService',
        'CoachService',
        'EntityService',
        'MealPlanService',
        'constants',
        'AnalyticService',
        'PaymentService'
    ];

    function CustomerProfileController($scope, $rootScope, $location, $routeParams, $mdDialog, utilService,
                                       ModalService, CustomerService, CoachService, EntityService,
                                       MealPlanService, constants, AnalyticService, PaymentService) {
        var vm = this;
        vm.tabNames = CUSTOMER_PROFILE_TAB_NAMES;
        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.mainTabName = null;
        vm.searchButtonText = '+ Add new scan';
        vm.suggestionType = constants.suggestionType;
        vm.mainProfileSpinner = { active: false };
        vm.inbodyTypes = constants.inbodyTypes;
        vm.scanDates = [];
        vm.canSeeCompliance = canSeeCompliance(vm.loggedInUser.role);
        vm.isChildEntity = utilService.getIsChildEntity();
        vm.customerAvailableModules = {};
        vm.paymentFlowType = {};
        vm.isCoach = utilService.getIsCoachRole();
        vm.customerId = $routeParams.id;

        vm.getUserInfoById = getUserInfoById;
        vm.popop = popop;
        vm.showEditPlanDialog = showEditPlanDialog;
        vm.showMessageDialog = showMessageDialog;
        vm.getScanDates = getScanDates;
        vm.addNewScan = addNewScan;
        vm.goTo = goTo;
        vm.getCurrentEntity = getCurrentEntity;
        vm.checkIn = checkIn;
        vm.setEntityInfo = setEntityInfo;
        vm.getEntityInfoBySuperuser = getEntityInfoBySuperuser;
        vm.getEntityInfoByAdmin = getEntityInfoByAdmin;
        vm.getEntityInfoByAgent = getEntityInfoByAgent;
        vm.goBack = goBack;
        vm.getNewInbodyScans = getNewInbodyScans;
        vm.markUserAsSeen = markUserAsSeen;
        vm.onAddScanManually = onAddScanManually;
        vm.openChatWithCurrentCustomer = openChatWithCurrentCustomer;
        vm.setCustomerAvailableModules = setCustomerAvailableModules;
        vm.setInitialTab = setInitialTab;
        vm.setPaymentFlowType = setPaymentFlowType;

        // run on changeMealPlanDialog in modal service (mainTabName: constants.tabNames.MEAL_PLAN, tabName: editPage (EDIT_MACRO_PLAN or SUGGESTED_MEAL_PLAN))
        $scope.$on('updateMealPlanTab', function (event, opt) {
            vm.goTo(opt.mainTabName, opt.tabName);
        });

        vm.$onInit = async () => {
            await Promise.all([
              vm.setCustomerAvailableModules(),
              vm.getUserInfoById(),
              vm.getScanDates(),
            ]);

            // dependent on currentCustomerDetails
            await Promise.all([
                vm.getCurrentEntity(),
                vm.setPaymentFlowType()
            ]);
          
            vm.setInitialTab();
          
            utilService.applyAsyncFunc($scope);
        };

        function setInitialTab() {
            const {initialTabName} = pathQueryParams;
            const initialTab = $routeParams[initialTabName];

            if (CUSTOMER_PROFILE_TAB_NAMES[initialTab]) {
                vm.mainTabName = initialTab;
            } else {
                vm.mainTabName = vm.tabNames.CUSTOMER_OVERVIEW;
            }            
        }

        function goBack () {
            const isCoach = utilService.getIsCoachRole();
            const { ACTIVE_CALENDAR, SCHOLASTIC } = vm.customerAvailableModules;

            if (isCoach) {
                utilService.goTo('/customers-dashboard');
            } else if (ACTIVE_CALENDAR) {
                utilService.goTo('active-calendar/users');
            } else if (SCHOLASTIC) {
                utilService.goTo('scholastic-customers-list');
            } else {
                utilService.goTo('/customers-dashboard');
            }
        }

        function getUserInfoById() {
            vm.mainProfileSpinner.active = true;
            return CustomerService.getCustomerDetails(vm.loggedInUser.id, $routeParams.id).then(function (res) {
                vm.mainProfileSpinner.active = false;
                if (res.status === 200) {
                    localStorage.setItem('complianceCoachForCustomer',
                        JSON.stringify(vm.loggedInUser.id !== res.data.coach.id && vm.loggedInUser.role === 'AGENT'));
                    vm.currentCustomerDetails = res.data;
                    if ($location.search().hasScansAvailable === 'true') {
                        vm.getNewInbodyScans();
                    }
                }
            });
        }

        function markUserAsSeen() {
            CustomerService.markUserAsSeen($routeParams.id).then(function (result) {
                if (result.status === 200) {
                    $location.search('hasScansAvailable', null);
                }
            });
        }

        function canSeeCompliance(role) {
            return ['ADMIN','SUPERUSER','AGENT'].indexOf(role) >=0;
        }

        async function getCurrentEntity() {
            switch (vm.loggedInUser.role) {
                case 'SUPERUSER':
                    await vm.getEntityInfoBySuperuser();
                    break;
                case 'ADMIN':
                    await vm.getEntityInfoByAdmin();
                    break;
                case 'AGENT':
                    await vm.getEntityInfoByAgent();
                    break;
            }

            utilService.applyAsyncFunc($scope);
        }

        function getEntityInfoBySuperuser() {
           return EntityService.getEntityById(vm.currentCustomerDetails.gymId).then(function (result) {
                if (result.status === 200) {
                    vm.setEntityInfo(result.data);
                }
            })
        }

        function getEntityInfoByAdmin() {
            return EntityService.getEntitiesOfAdmin().then(function (result) {
                if (result.status === 200) {
                    vm.setEntityInfo(result.data.entities[0]);
                }
            });
        }

        function getEntityInfoByAgent() {
            return CoachService.getCurrentEntityInfo().then(function (result) {
                if (result.status === 200) {
                    vm.setEntityInfo(result.data);
                }
            })
        }

        function setEntityInfo(entity) {
            localStorage.setItem('supportedInbodyTypes', JSON.stringify(entity.supportedInbodyTypes));
            localStorage.setItem('entryFields', JSON.stringify(entity.entryFields));
            localStorage.setItem('allowNonInbodyUsers', JSON.stringify(entity.allowNonInbodyUsers));
        }

        function popop(data) {
            if (data?.status === 'Success') {
                AnalyticService.pushEvent('addEvents_makeConversions', 'd-v1-e3', 'scan', 'add',
                    {'customer_id': $routeParams.id});
            }
            vm.getScanDates().then(function () {
                // should check because getMealAdjustments return error when less than 2 scans
                if (vm.scanDates.length >= 2) {
                    MealPlanService.getMealAdjustments($routeParams.id).then(function (result) {
                        if (result.status === 200) {
                            ModalService.changeMealPlanDialog(
                                vm.currentCustomerDetails,
                                data.status,
                                vm.mainTabName,
                                result.data.type === vm.suggestionType.SCENARIO,
                                vm.scanDates
                            );
                        } else if (result.status === 404) {
                            ModalService.changeMealPlanDialog(vm.currentCustomerDetails, data.status, vm.mainTabName, false, vm.scanDates)
                        }
                    })
                } else {
                    ModalService.changeMealPlanDialog(vm.currentCustomerDetails, 'Success', vm.mainTabName, false, vm.scanDates)
                }
            });
        }

        function getNewInbodyScans() {
            vm.popop({status: 'Success'});
            vm.markUserAsSeen();
            AnalyticService.pushEvent('addEvents_makeConversions', 'd-v1-e5', 'scan', 'open_user_with_scan_from_webhook',
                {'customer_id': $routeParams.id});
        }

        function showEditPlanDialog() {
            vm.searchButtonText = 'Adding...';
            CustomerService.getInbodyDataForUser($routeParams.id).then(function (res) {
                vm.searchButtonText = '+ Add new scan';
                switch (res.status) {
                    case 200:
                        vm.popop(res.data);
                        break;
                    default:
                        vm.addNewScan(false);
                        break;
                }
            });
            AnalyticService.pushEvent('addEvents_makeConversions', 'd-v1-e2', 'scan', 'click_btn_add_new_scan',
                {'customer_id': $routeParams.id});
        }

        function checkIn() {
            var entryFields = JSON.parse(localStorage.getItem('entryFields'));
            ModalService.checkInDialog(
                vm.currentCustomerDetails.customer.inbodyType,
                entryFields,
                $routeParams.id,
                vm.popop
            );
        }

        function showMessageDialog() {
            ModalService.sendMessage(
                { via: 'email', userIds: [$routeParams.id] },
                [vm.currentCustomerDetails.id],
                [vm.currentCustomerDetails.id],
                true
            )
        }

        function getScanDates() {
            return CustomerService.getUserScanDates($routeParams.id).then(function (res) {
                if (res.status === 200) {
                    vm.scanDates = res.data.scanDates;
                    vm.lastScan = res.data.scanDates.length > 0 ? res.data.scanDates[0] : null;
                }
            });
        }

        function addNewScan(status) {
            ModalService.addNewScan(vm.currentCustomerDetails.customer.inbodyType, status, $routeParams.id, vm.popop);
        }

        function goTo(mainTabName, tabName) {
            vm.mainTabName = mainTabName;

            if (vm.mainTabName === vm.tabNames.MEAL_PLAN) {
                localStorage.setItem(tabName, JSON.stringify(true));
            }
        }

        function onAddScanManually() {
            vm.addNewScan();
            AnalyticService.pushEvent('addEvents_makeConversions', 'd-v1-e4', 'scan', 'click_btn_add_scan_manually',
                {'customer_id': $routeParams.id});
        }

        async function openChatWithCurrentCustomer() {
            const { setActiveChannelId, setIsOpenChat } = chatStore;

            const channelId = await getChannelId();

            setActiveChannelId(channelId);
            setIsOpenChat(true);
        }

        async function setCustomerAvailableModules() {
            const customerAvailableModules = await getCustomerAvailableModules($routeParams.id);
            vm.customerAvailableModules = customerAvailableModules;
        }
        
        async function getChannelId() {
            try {
                vm.mainProfileSpinner.active = true;
                return await chatService.getChannelId(vm.currentCustomerDetails.id);
            } finally {
                vm.mainProfileSpinner.active = false;
                $scope.$apply();
            }
        }
        // TODO no spinner, current one will be closed after 1st request on init
        async function setPaymentFlowType() {
            vm.paymentFlowType = await PaymentService.getPaymentFlowTypeFlags(vm.currentCustomerDetails.gymId);

            utilService.applyAsyncFunc($scope);
        }
    }
})();
