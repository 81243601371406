import { InbodyDataField } from '../../../../../../shared/constants/inbody-info/inbodyData';
import { KeysOfType } from '../../../../../../utils-types';
import { InbodyScanMetrics } from '../../../../../interfaces/customerInbodyData';

export type CompareMetricConfig = {
  label: string;
  configField: InbodyDataField;
  scanField: KeysOfType<InbodyScanMetrics, number>;
};

// improve this create a function to get the config use the inbodyDataConfig like template refactor scanField (InbodyScanMetrics)
export const compareScansTableConfig: CompareMetricConfig[] = [
  { label: 'Weight', configField: 'weight', scanField: 'body_comp_weight' },
  { label: 'SMM', configField: 'smm', scanField: 'muscle_fat_sceleton_mass' },
  {
    label: 'Body Fat Mass, lbs',
    configField: 'poundOfFat',
    scanField: 'body_comp_bodyFatMass',
  },
  { label: 'TBW', configField: 'tbw', scanField: 'body_comp_bodyWater' },
  { label: 'ICW', configField: 'icw', scanField: 'body_comp_intracellularWater' },
  { label: 'ECW', configField: 'ecw', scanField: 'body_comp_extracellularWater' },
  { label: 'DLM', configField: 'dlm', scanField: 'body_comp_dryLeanMass' },
  { label: 'ECW/TBW', configField: 'ecwTbw', scanField: 'ecw_tbw' },
];
